import constants from '../../../../components/entity/constants';
import { createDataSetFacet, createField } from '../../../../components/helpers/fieldHelper';

export const getHeaderContent = (t) => [
    createDataSetFacet(t("zones.view.facet_information"), [
        createField("id", "Id", constants.STRING_TYPE),
        createField(
            "name",
            t("zones.fields.name"),
            constants.STRING_TYPE
        ),
        createField(
            "area.name",
            t("zones.fields.area"),
            constants.STRING_TYPE
        ),
    ])
];