import { useTranslation } from "react-i18next"
import { useVolcanoApiClient } from "../../../context/VolcanoApiClientProvider"
import CollectionPage from "../../../layout/templates/CollectionPage/CollectionPage"
import { useVolcanoAuth } from '../../../context/VolcanoAuthContext'
import { useNavigate } from 'react-router-dom'
import { getTableFilter } from './NotificationsCollection.filter'
import { getActions } from "./NotificationsCollection.actions"
import { getTableColumns } from './NotificationsCollection.columns'
import { useCache } from "../../../context/cache.context"
import _get from "lodash/get"
import _isArray from "lodash/isArray"

const NotificationsCollectionPage = () => {
    const { apiClient, siteConfig } = useVolcanoApiClient()
    const { t } = useTranslation("vbms")
    const volcanoAuth = useVolcanoAuth()
    const navigate = useNavigate()
    const cache = useCache()

    const filterConfig = getTableFilter(apiClient, siteConfig, t, volcanoAuth.user, cache)

    const table = {
        columns: getTableColumns(t, volcanoAuth.user),
        fetcher: (params) => {

/*            let productsFilter = [];
            if (_get(params, "product_id", null)) {
                if (_isArray(_get(params, "product_id", null))) {
                    _get(params, "product_id", null).forEach(prd => {
                        productsFilter.push(prd.id)
                    });
                } else {
                    productsFilter.push(_get(params, "product_id", null));
                }
            }*/

            const queryFilter = {
                ...params,
                //product_id: productsFilter
            }

            return apiClient.notifications.getNotifications(queryFilter)
        }
    };

    return (
        <CollectionPage
            title={t("notifications.title")}
            subtitle={t("notifications.subtitle")}
            actions={getActions(t, navigate, volcanoAuth.user)}
            table={table}
            filterConfig={filterConfig}
        />
    )
}

export default NotificationsCollectionPage;
