import { useTranslation } from "react-i18next";
import _isEmpty from "lodash/isEmpty"
import { createLanguagesMapping, languagesCode2 } from "../../../../../../components/helpers/fieldHelper";
import { CollapseLanguage, getRecipientDetails } from "./NotificationRecipients.functions";
import { Box } from "@mui/material";
import Title from "../../../../../../components/Display/Title";



const NotificationRecipientsDetails = ({ entityId, recipients, edit }) => {
    const { t } = useTranslation("vbms");

    const languagesMap = createLanguagesMapping(t, languagesCode2(), true)

    return (
        <>
        {recipients.map((recipient) => {
            const recipientTemplates = getRecipientDetails(recipient.templates);
            return (
                <Box pb={2} key={'b'+recipient.type}>
                    <Title level="h3">{t("notification_templates.constants."+recipient.type)}</Title>
                    {recipientTemplates.map((template, index) => {
                        const elementId = recipient.type + "-" + index
                        return (
                            <CollapseLanguage
                                url={'notifications'}
                                template={template}
                                parentKey={elementId}
                                languages={languagesMap}
                                key={'cl_'+elementId}
                                entityId={entityId}
                                recipientType={recipient.type}
                                t={t}
                                edit={edit}
                                />
                        )
                    })}
                </Box>
            )
        })}
        </>
    )
}
export default NotificationRecipientsDetails