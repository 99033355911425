import availableRoutes from '../../../routes/availableRoutes'
import NotificationAddProduct from "./components/actions/NotificationAddProduct"
import NotificationSetDate from './components/actions/NotificationSetDate'
import _isEmpty from 'lodash/isEmpty'

export const getActions = (user, apiClient, entityId, t, navigate) => [
    {
        id: "prepare",
        title: t("notifications.actions.prepare.button"),
        reloadOnSuccess: true,
        showLoading: true,
        onExecute: (data, progressHandler, onSuccess, onError) => {
            return apiClient.notifications.prepareNotification(entityId)
                .then((data) => {
                    onSuccess(t("notifications.actions.prepare.confirm"))
                    navigate(availableRoutes.notifications_view.path.replace(":entityId", entityId))
                })
                .catch((error) => {
                    onError(t("notifications.actions.prepare.error"))
                    throw error
                })
        },
        condition: (entity) => entity.hasAction("prepare")
    },
    {
        id: "set_date",
        title: t("notifications.actions.set_date.button"),
        reloadOnSuccess: true,
        showLoading: true,
        withDialog: {
            title: t("notifications.actions.set_date.modal_title"),
            content: NotificationSetDate
        },
        onExecute: (data, progressHandler, onSuccess, onError) => {
            return apiClient.notifications.setDateNotification(entityId, data)
                .then(() => {
                    onSuccess(t("notifications.actions.set_date.confirm"))
                    navigate(availableRoutes.notifications_view.path.replace(":entityId", entityId))
                })
                .catch((error) => {
                    onError(t("notifications.actions.set_date.error"))
                    throw error
                })
        },
        condition: (entity) => entity.hasAction("edit")
    },
    {
        id: "configure_sessions",
        title: t("notifications.actions.configure_sessions.button"),
        onExecute: (entity, progressHandler, onSuccess, onError) => {
            navigate(availableRoutes.notifications_sessions_edit.path.replace(":entityId", entity.id))
        },
        condition: (entity) => {
            return entity.hasAction("edit") && entity.date && entity.state === 'edition'
        }

    },
    {
        id: "configure_prepare_sessions",
        title: t("notifications.actions.configure_sessions.button"),
        reloadOnSuccess: true,
        showLoading: true,
        confirmDialog: {
            title: t("notifications.actions.configure_sessions.modal_title"),
            content: (selection) =>
                t("notifications.actions.configure_sessions.modal_content", {
                    name: selection.name,
                }),
        },
        onExecute: (data, progressHandler, onSuccess, onError) => {
            return apiClient.notifications.setEditNotification(entityId)
                .then(() => {
                    onSuccess(t("notifications.actions.configure_sessions.confirm"))
                    navigate(availableRoutes.notifications_sessions_edit.path.replace(":entityId", entityId))
                })
                .catch((error) => {
                    onError(t("notifications.actions.configure_sessions.error"))
                    throw error
                })
        },
        condition: (entity) => entity.hasAction("edit") && entity.date && entity.state === 'prepare'

    },
    {
        id: "add_product",
        title: t("notifications.actions.add_product.button"),
        reloadOnSuccess: true,
        showLoading: true,
        withDialog: {
            title: t("notifications.actions.add_product.modal_title"),
            content: NotificationAddProduct
        },
        onExecute: (data, progressHandler, onSuccess, onError) => {
            return apiClient.notifications.addProductToNotification(entityId, data.products)
                .then((data) => {
                    onSuccess(t("notifications.actions.add_product.confirm"))
                    navigate(availableRoutes.notifications_view.path.replace(":entityId", entityId))
                })
                .catch((error) => {
                    onError(t("notifications.actions.add_product.error"))
                    throw error
                })
        },
        condition: (entity) => entity.hasAction("edit")
    },
    {
        id: "edit",
        title: t("common.edit"),
        onExecute: (user, progressHandler, onSuccess, onError) => {
            navigate(availableRoutes.notifications_edit.path.replace(":entityId", entityId))
        },
        condition: (entity) => entity.hasAction("edit")

    },
    {
        id: "send",
        title: t("notifications.actions.send.button"),
        reloadOnSuccess: true,
        showLoading: true,
        confirmDialog: {
            title: t("notifications.actions.send.modal_title"),
            content: (selection) =>
                t("notifications.actions.send.modal_content", {
                    name: selection.name,
                }),
        },
        onExecute: (data, progressHandler, onSuccess, onError) => {
            return apiClient.notifications.sendNotification(entityId)
                .then((data) => {
                    onSuccess(t("notifications.actions.send.confirm"))
                    navigate(availableRoutes.notifications_view.path.replace(":entityId", entityId))
                })
                .catch((error) => {
                    onError(t("notifications.actions.send.error"))
                    throw error
                })
        },
        condition: (entity) => (user.hasPermission("administer-any-notification")
                        && (entity.state === 'ready') && !_isEmpty(entity.recipients))
    },
    {
        id: "set_edit",
        title: t("notifications.actions.edit.modal_title"),
        confirmDialog: {
            title: t("notifications.actions.edit.modal_title"),
            content: (selection) =>
                t("notifications.actions.edit.modal_content"),
        },
        showLoading: true,
        onExecute: (user, progressHandler, onSuccess, onError) => {
            return apiClient.notifications.setEditNotification(entityId)
                .then((result) => {
                    onSuccess(
                        t("notifications.actions.edit.confirm")
                    )
                    navigate(availableRoutes.notifications_view.path.replace(":entityId", entityId))
                })
                .catch((error) => {
                    onError(
                        t("notifications.actions.edit.error")
                    )

                    throw error
                })
        },
        condition: (entity) => (user.hasPermission("administer-any-notification")
                        && (entity.state === 'ready'))

    },    
    {
        id: "cancel",
        title: t("notifications.actions.cancel.button"),
        confirmDialog: {
            title: t("notifications.actions.cancel.modal_title"),
            content: (selection) =>
                t("notifications.actions.cancel.modal_content", {
                    name: selection.name,
                }),
        },
        showLoading: true,
        onExecute: (notification, progressHandler, onSuccess, onError) => {
            return apiClient.notifications.cancelNotification(entityId)
                .then((result) => {
                    onSuccess(
                        t("notifications.actions.cancel.confirm", {
                            name: notification.name,
                        })
                    )
                    navigate(availableRoutes.notifications)
                })
                .catch((error) => {
                    onError(
                        t("notifications.actions.cancel.error", {
                            name: notification.name,
                        })
                    )

                    throw error
                })
        },
        condition: (entity) => (user.hasPermission("administer-any-notification")
                        && (entity.state === 'pending'))
    },
    {
        id: "delete",
        title: t("common.delete"),
        confirmDialog: {
            title: t("notifications.actions.delete.modal_title"),
            content: (selection) =>
                t("notifications.actions.delete.modal_content", {
                    name: selection.name,
                }),
        },
        showLoading: true,
        onExecute: (notification, progressHandler, onSuccess, onError) => {
            return apiClient.notifications.deleteNotification(entityId)
                .then((result) => {
                    onSuccess(
                        t("notifications.actions.delete.confirm", {
                            name: notification.name,
                        })
                    )
                    navigate(availableRoutes.notifications.path)
                })
                .catch((error) => {
                    onError(
                        t("notifications.actions.delete.error", {
                            name: notification.name,
                        })
                    )

                    throw error
                })
        },
        condition: (entity) => entity.hasAction("delete")
    }

]
