import { useTranslation } from "react-i18next"
import { useVolcanoApiClient } from "../../../context/VolcanoApiClientProvider"
import { useVolcanoAuth } from "../../../context/VolcanoAuthContext";
import { useCache } from "../../../context/cache.context";
import { createAsyncSelectFormField, createField, createFormFieldGroup, createFormFieldset, createFormField } from '../../../components/helpers/fieldHelper';
import {areasFetcher, DEFAULT_LIMIT} from '../../../lib/form-options-fetchers';
import constants from "../../../components/entity/constants"
import AsyncEntityForm from "../../../components/form/AsyncEntityForm";
const ZoneForm =  ({ selection, onAction }) => {

    const cache = useCache()
    const { t } = useTranslation("vbms")
    const { apiClient } = useVolcanoApiClient()
    const { user } = useVolcanoAuth()

    const fetcher = () => Promise.resolve(selection === undefined ? {} : selection)

    const onConfirm = (data) => {

        const zone = {
            ...data
        }
        zone['enterprise_id'] = user.corporate_account.enterprise.id

        onAction(zone)
    }

    const formContent = [
        createFormFieldset(t("zones.form.fieldset_information"),
            [
                createFormFieldGroup([
                    createFormField(
                        createField(
                            "name",
                            t("zones.fields.name"),
                            constants.STRING_TYPE
                        ),
                        true
                    )
                ]),
                createFormField(
                    createAsyncSelectFormField(
                        "area.id",
                        t("zones.fields.area"),
                        () => areasFetcher(cache, apiClient, t, {
                            code: "AR",
                            limit: DEFAULT_LIMIT
                        },false),
                        false,
                        () => user.hasPermission("access-any-enterprise")
                    ),
                    true,
                    ""
                )
            ])
    ]

    return (
        <AsyncEntityForm
            fetcher={fetcher}
            onSave={onConfirm}
            formContent={formContent}
        />
    )
}

export default ZoneForm