import availableRoutes from '../../../routes/availableRoutes'

export const getActions = (t, navigate, user) => [
/*    {
        title: t("notifications.actions.add_title"),
        onExecute: (user, progressHandler, onSuccess, onError) => {
            navigate(availableRoutes.notifications_add.path)
        },
        condition: () => user.hasPermission('administer-any-notification')
    },*/
]