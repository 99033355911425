import { useTranslation } from 'react-i18next';
import ArrayDataTable from '../../../../../../components/Datatable/ArrayDataTable';
import {getTableColumns} from "../../../../components/hooks/PickupPointsCollection.columns";

const ZonePickupPoints = (props) => {
    const { t } = useTranslation("vbms")

    return (
        <ArrayDataTable
            data={props.data}
            columns={getTableColumns(t)}
        />
    )
}

export default ZonePickupPoints