import { useTranslation } from "react-i18next"
import { createCallbackField, createFormFieldset } from "../../../../../components/helpers/fieldHelper"
import AsyncEntityForm from "../../../../../components/form/AsyncEntityForm"
import constants from "../../../../../components/entity/constants"
import _set from "lodash/set"
import _get from "lodash/get"
import _isDate from "lodash/isDate"
import { format as formatDate } from "date-fns"
import { useDialog } from "../../../../../components/Dialog/dialog.context"


const NotificationSetDate = ({ selection, onAction, onCancel }) => {
    const { t } = useTranslation("vbms")
    const { handleClose } = useDialog()

    const fetcher = () => Promise.resolve(selection)

    const formContent = [
        createFormFieldset(
            null, 
            [
                createCallbackField(
                    "date",
                    t("notifications.fields.date"),
                    constants.DATE_TYPE,
                    (value) => value ? new Date(value) : null
                )
            ])
    ]

    const onConfirm = (data) => {
        let date = _get(data, "date")
        if (_isDate(date)) {
            date = formatDate(date, "yyyy-MM-dd")
        }
        onAction({
            date
        }).finally(() => {
            onClose()
        })
    }

    const onClose = () => {
        handleClose()
    }

    return (
        <AsyncEntityForm
            fetcher={fetcher}
            onSave={onConfirm}
            formContent={formContent}
        />
    )
}

export default NotificationSetDate