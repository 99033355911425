import { useTranslation } from "react-i18next";
import { getRecipientsFinishedActions, getResult } from "./NotificationRecipients.functions";
import { createCallbackField, createField, createLanguagesMapping, createLinkField, createSelectFormField, languagesCode2 } from "../../../../../../components/helpers/fieldHelper";
import constants from "../../../../../../components/entity/constants";
import { Box } from "@mui/material";
import CollectionDataTable from "../../../../../../components/Datatable/CollectionDataTable";
import { useVolcanoApiClient } from "../../../../../../context/VolcanoApiClientProvider";
import { useState } from "react";
import { MailOutlineOutlined, SmsOutlined } from "@mui/icons-material";

const getTableFilter = (t) => ([
    createField(
        "name",
        t("notifications.filter.name"),
        constants.TEXT_TYPE
    ),
    createField(
        "email",
        t("notifications.fields.email"),
        constants.TEXT_TYPE
    ),
    createField(
        "phone",
        t("notifications.fields.phone"),
        constants.TEXT_TYPE
    ),
    createSelectFormField(
        "languages",
        t("notification_templates.fields.recipients.language"),
        Object.entries(createLanguagesMapping(t, languagesCode2(), true))
            .map(([key, value]) => (
                {
                    value: key,
                    label: value
                }
            )),
    ),
])


const NotificationRecipientsIntermediaries = ({ defaultParams }) => {
    const { t } = useTranslation("vbms");
    const { apiClient } = useVolcanoApiClient()

    const entityId = useState(defaultParams.notification_id)

    const actions = getRecipientsFinishedActions(entityId, 'collaborator', t)
 
    const filterConfig = getTableFilter(t)

    const columns = [
        createLinkField(
            createField(
                "name",
                t("notifications.filter.name"),
                constants.TEXT_TYPE
            ),
            {
                route: "intermediaries",
                field: "crm_intermediary_id",
            }
        ),
        createField(
            "email",
            t("notifications.fields.email"),
            constants.STRING_TYPE
        ),
        createField(
            "phone",
            t("notifications.fields.phone"),
            constants.STRING_TYPE
        ),
        createField(
            "language",
            t("notifications.fields.language"),
            constants.STRING_TYPE,
            createLanguagesMapping(t)
        ),
        createCallbackField(
            "logs",
            <MailOutlineOutlined/>,
            constants.STRING_TYPE,
            (value) => {
                if (value && value["email"]) {
                    return (
                        <Box>{getResult(value["email"], t)}</Box>
                    )
                } else {
                    return
                }
            },
        ),
        createCallbackField(
            "logs",
            <SmsOutlined/>,
            constants.STRING_TYPE,
            (value) => {
                if (value && value["sms"]) {
                    return (
                        <Box>{getResult(value["sms"], t)}</Box>
                    )
                } else {
                    return
                }
            },
        ),
    ]

    return (
        <CollectionDataTable
            fetcher={(query) => {
                const params = {
                    ...defaultParams
                    , ...query
                }
                return apiClient.notifications.getRecipients(defaultParams.notification_id, params)
            }}
            columns={columns}
            actions={actions}
            filterConfig={filterConfig}
        />
    )

}

export default NotificationRecipientsIntermediaries