import { downloadFile } from '../../../lib/utils'
import _has from 'lodash/has'
import _set from 'lodash/set'
import _get from 'lodash/get'
import { unset } from 'lodash'
import { parseISO, format as formatDate } from 'date-fns'
import BookingDateChange from './components/actions/BookingDateChange'
import BookingSendVoucher from './components/actions/BookingSendVoucher'
import BookingClientEdit from './components/actions/BookingClientEdit'
import BookingActivityPickupChange from './components/actions/BookingActivityPickupChange'
import BookingAssignPaxManagerCollaborator from './components/actions/BookingAssignPaxManagerCollaborator'
import CommentForm from '../../../components/Volcano/Comments/CommentForm'
import BookingProductChange from './components/actions/BookingProductChange/BookingProductChange'
import BookingActionRequestWithRefundCost from '../components/BookingActionRequestWithRefundCost'
import availableRoutes from '../../../routes/availableRoutes';
import BookingSetValidationDate from './components/actions/BookingSetValidationDate'
import BookingSetNoShow from './components/actions/BookingSetNoShow'
import BookingRevertNoShow from './components/actions/BookingRevertNoShow'
import BookingSetIntermediary from './components/actions/BookingSetIntermediary'
import BookingSetVoucher from './components/actions/BookingSetVoucher'
import BookingSetNotes from './components/actions/BookingSetNotes'
import BookingInfoGift from './components/actions/BookingInfoGift'

export const getActions = (user, apiClient, entityId, t, navigate) => [
    {
        id: "change_date",
        title: t("bookings.actions.change_date.button"),
        withDialog: {
            title: t("bookings.actions.change_date.modal_title"),
            content: BookingDateChange
        },
        reloadOnSuccess: true,
        showLoading: true,
        onExecute: (result, progressHandler, onSuccess, onError) => {
            return apiClient.booking.changeBookingDate(entityId, parseISO(result.date + 'T' + result.session))
                .then((booking) => {
                    onSuccess(
                        t("bookings.actions.change_date.confirm", { locator: result.locator })
                    )

                    return { ...booking, id: entityId }
                })
                .catch((error) => {
                    onError(
                        t("bookings.actions.change_date.error", { locator: result.locator })
                    )

                    throw error
                })
        },
        condition: (booking) => _has(booking.getActions(), "booking_date_change")
    },
    {
        id: "delete_date",
        title: t("bookings.actions.date_delete.button"),
        confirmDialog: {
            title: t("bookings.actions.date_delete.modal_title"),
            content: (selection) =>
                t("bookings.actions.date_delete.modal_content", {
                    id: selection.id,
                    locator: selection.locator,
                })
        },
        reloadOnSuccess: true,
        showLoading: true,
        onExecute: (result, progressHandler, onSuccess, onError) => {
            return apiClient.booking.deleteBookingDate(entityId)
                .then((booking) => {
                    onSuccess(
                        t("bookings.actions.date_delete.confirm", { locator: result.locator })
                    )

                    return { ...booking, id: entityId }
                })
                .catch((error) => {
                    onError(
                        t("bookings.actions.date_delete.error", { locator: result.locator })
                    )

                    throw error
                })
        },
        condition: (booking) => {
            return (
                _has(booking.getActions(), "booking_date_delete") &&
                _has(booking, "booking_date") &&
                !user.isIntermediary()
            )
        }


    },
    /*{
        id: "request_cancel",
        title: t("bookings.actions.request_cancel.button"),
        confirmDialog: {
            title: t("bookings.actions.request_cancel.modal_title"),
            content: (selection) => {
                return _get(selection, '_links.cancellation_request.data.confirmation_conditions_message')
            },
        },
        reloadOnSuccess: true,
        showLoading: true,
        onExecute: (booking, progressHandler, onSuccess, onError) => {
            return apiClient.booking
                .cancelBooking(entityId)
                .then((booking) => {
                    onSuccess(
                        t((booking.cancel_state === 1) ?
                            "bookings.actions.request_cancel.cancelled" :
                            "bookings.actions.request_cancel.confirm", {
                            locator: booking.locator
                        })
                    )

                    return booking
                })
                .catch((error) => {
                    onError(
                        t("bookings.actions.request_cancel.error", {
                            locator: booking.locator,
                        })
                    )
                })
        },
        condition: (booking) => booking.hasAction("cancellation_request") && (!booking.hasAction("cancellation_request", "set_refund_cost") || (booking.refund_cost.value === 0))
    },*/
    {
        id: "request_cancel_set_cost",
        title: t("bookings.actions.request_cancel.button"),
        withDialog: {
            title: t("bookings.actions.request_cancel.modal_title"),
            content: ({ selection, onAction, onCancel }) => {
                const allowEditcost = selection && selection.hasAction("cancellation_request", "set_refund_cost")

                const data = {
                    refund_cost: _get(selection, "refund_cost", null),
                }

                const onActionHandler = (result) => {
                    const data = {
                        locator: selection.locator
                    }

                    if (!allowEditcost) {
                        onAction(data)
                        return
                    }

                    if (_get(result, "refund_cost", null) !== null) {
                        _set(data, "refund_cost", result.refund_cost)
                    }

                    if (_get(result, "comment", null) !== null) {
                        _set(data, "comment", result.comment)
                    }

                    onAction(data)
                }

                if (allowEditcost &&
                    selection.refund_cost.value === 0 &&
                    selection.refund_pax_cost === null) {
                    unset(data, "refund_cost")
                }

                return <BookingActionRequestWithRefundCost
                    selection={data}
                    allowEditCost={allowEditcost}
                    messages={{
                        conditions_text: _get(selection, "_links.cancellation_request.data.confirmation_conditions_message"),
                        confirm_text_not_allowed: "bookings.actions.request_cancel.confirm_text_not_allowed",
                    }}
                    currency={selection.order.currency}
                    mainCurrency={selection.order.main_currency}
                    exchangeRate={selection.order.currency_exchange_rate}
                    onAction={onActionHandler}
                    onCancel={onCancel}
                />
            },
        },
        reloadOnSuccess: true,
        showLoading: true,
        onExecute: (data, progressHandler, onSuccess, onError) => {
            return apiClient.booking
                .cancelBooking(entityId, data)
                .then((booking) => {
                    onSuccess(
                        t((booking.cancel_state === 1) ?
                            "bookings.actions.request_cancel.cancelled" :
                            "bookings.actions.request_cancel.confirm", {
                            locator: booking.locator
                        })
                    )

                    return booking
                })
                .catch((error) => {
                    onError(
                        t("bookings.actions.request_cancel.error", {
                            locator: data.locator,
                        })
                    )
                })
        },
        condition: (booking) => booking.hasAction("cancellation_request")
    },
    {
        id: "set_no_show",
        title: t("bookings.actions.set_no_show.button"),
        withDialog: {
            title: t("bookings.actions.set_no_show.modal_title"),
            content: BookingSetNoShow
        },
        reloadOnSuccess: true,
        showLoading: true,
        onExecute: (result, progressHandler, onSuccess, onError) => {
            return apiClient.booking.setNoShow(entityId, { cost: result.cost })
                .then((booking) => {
                    onSuccess(
                        t("bookings.actions.set_no_show.confirm", { locator: result.locator })
                    )

                    return { ...booking, id: entityId }
                })
                .catch((error) => {
                    onError(
                        t("bookings.actions.set_no_show.error", { locator: result.locator })
                    )

                    throw error
                })
        },
        condition: (booking) => _has(booking.getActions(), "set_no_show")
    },
    {
        id: "revert_no_show",
        title: t("bookings.actions.revert_no_show.button"),
        withDialog: {
            title: t("bookings.actions.revert_no_show.modal_title"),
            content: BookingRevertNoShow
        },
        reloadOnSuccess: true,
        showLoading: true,
        onExecute: (result, progressHandler, onSuccess, onError) => {
            return apiClient.booking.revertNoShow(entityId)
                .then((booking) => {
                    onSuccess(
                        t("bookings.actions.revert_no_show.confirm", { locator: result.locator })
                    )

                    return { ...booking, id: entityId }
                })
                .catch((error) => {
                    onError(
                        t("bookings.actions.revert_no_show.error", { locator: result.locator })
                    )

                    throw error
                })
        },
        condition: (booking) => _has(booking.getActions(), "revert_no_show")
    },
    {
        id: "set_exchange_date",
        title: t("bookings.actions.validate.button"),
        withDialog: {
            title: t("bookings.actions.validate.modal_title"),
            content: BookingSetValidationDate
        },
        reloadOnSuccess: true,
        showLoading: true,
        onExecute: (result, progressHandler, onSuccess, onError) => {
            const validationDate = formatDate(result.booking_date, "yyyy-MM-dd HH:mm:ss")
            return apiClient.booking.setValidationDate(entityId, { exchange_date: validationDate })
                .then((booking) => {
                    onSuccess(
                        t("bookings.actions.validate.confirm", { locator: result.locator })
                    )

                    return { ...booking, id: entityId }
                })
                .catch((error) => {
                    onError(
                        t("bookings.actions.validate.error", { locator: result.locator })
                    )

                    throw error
                })
        },
        condition: (booking) => _has(booking.getActions(), "set_exchange_date")
    },
    {
        id: "unset_exchange_date",
        title: t("bookings.actions.unvalidate.button"),
        confirmDialog: {
            title: t("bookings.actions.unvalidate.modal_title"),
            content: (selection) =>
                t("bookings.actions.unvalidate.modal_content", {
                    id: selection.id,
                    locator: selection.locator,
                })
        },
        reloadOnSuccess: true,
        showLoading: true,
        onExecute: (result, progressHandler, onSuccess, onError) => {
            return apiClient.booking.deleteValidation(entityId)
                .then((booking) => {
                    onSuccess(
                        t("bookings.actions.unvalidate.confirm", { locator: result.locator })
                    )

                    return { ...booking, id: entityId }
                })
                .catch((error) => {
                    onError(
                        t("bookings.actions.unvalidate.error", { locator: result.locator })
                    )

                    throw error
                })
        },
        condition: (booking) => _has(booking.getActions(), "delete_exchange_date")
    },
    {
        id: "rates_edit",
        title: t("bookings.actions.rates_edit.button"),
        onExecute: (booking, progressHandler, onSuccess, onError) => {
            navigate('/orders/bookings/' + booking.id + '/rates-edit')
        },
        condition: (booking) => _has(booking.getActions(), "rates_change_request")
    },
    {
        id: "product_change",
        title: t("bookings.actions.product_change.button"),
        withDialog: {
            title: t("bookings.actions.product_change.modal_title"),
            content: BookingProductChange,
        },
        reloadOnSuccess: true,
        showLoading: true,
        onExecute: (result, progressHandler, onSuccess, onError) => {
            return apiClient.booking
                .changeProduct(entityId, result.data)
                .then((booking) => {
                    onSuccess(
                        t((booking.product.id === result.data.product_id) ?
                            "bookings.actions.product_change.confirm" :
                            "bookings.actions.product_change.confirm_with_refund", {
                            locator: booking.locator
                        })
                    )

                    return { ...booking, id: entityId }
                })
                .catch((error) => {
                    onError(
                        t("bookings.actions.product_change.error", {
                            locator: result.locator,
                        })
                    )

                    throw error
                })
        },
        condition: (booking) => _has(booking.getActions(), "product_change_request")
    },
    {
        id: "send_voucher",
        title: t("bookings.actions.send_voucher.button"),
        withDialog: {
            title: t("bookings.actions.send_voucher.modal_title"),
            content: BookingSendVoucher,
        },
        reloadOnSuccess: false,
        showLoading: true,
        onExecute: (result, progressHandler, onSuccess, onError) => {
            const notification = {}
            _set(notification, "notification.email.custom.emails", [result.order.customer.email])

            return apiClient.booking.sendBookingEmail(entityId, notification)
                .then((booking) => {
                    onSuccess(
                        t("bookings.actions.send_voucher.confirm", { locator: result.locator, email: result.order.customer.email })
                    )

                    return { ...booking, id: entityId }
                })
                .catch((error) => {
                    onError(
                        t("bookings.actions.send_voucher.error", { locator: result.locator, email: result.order.customer.email })
                    )

                    throw error
                })
        },
        condition: (entity) => entity.state === 'valid' || entity.state === 'refund_requested'
    },
    {
        id: "add_comment",
        title: t("common.actions.add_comment.button"),
        withDialog: {
            title: t("common.actions.add_comment.modal_title"),
            content: CommentForm,
        },
        reloadOnSuccess: true,
        showLoading: true,
        onExecute: (booking, progressHandler, onSuccess, onError) => {
            return apiClient.booking.addComment(entityId, booking.comment)
                .then((booking) => {
                    onSuccess(
                        t("common.actions.add_comment.confirm")
                    )

                    return { ...booking, id: entityId }
                })
                .catch((error) => {
                    onError(
                        t("common.actions.add_comment.error")
                    )

                    throw error
                })
        },
        condition: (booking) => _has(booking.getActions(), "add_comment")
    },
    {
        id: "client_edit",
        title: t("bookings.actions.client_edit.button"),
        withDialog: {
            title: t("bookings.actions.client_edit.modal_title"),
            content: BookingClientEdit,
        },
        reloadOnSuccess: true,
        showLoading: true,
        onExecute: (result, progressHandler, onSuccess, onError) => {
            const locator = result.locator
            return apiClient.order
                .changeOrderCustomer(result.order_id, result.customer_id, result.customer)
                .then((result) => {
                    onSuccess(
                        t("bookings.actions.client_edit.confirm", {
                            locator: locator,
                        })
                    )

                    return { ...result, id: entityId }
                })
                .catch((error) => {
                    onError(
                        t("bookings.actions.client_edit.error", {
                            locator: locator,
                        })
                    )
                })
        },
        condition: (booking) => _has(booking.getActions(), "edit_customer")
    },
    {
        id: "pickup_point_change",
        title: t("bookings.actions.pickup_point_change.button"),
        withDialog: {
            title: t("bookings.actions.pickup_point_change.modal_title"),
            content: BookingActivityPickupChange,
        },
        reloadOnSuccess: true,
        showLoading: true,
        onExecute: (result, progressHandler, onSuccess, onError) => {
            return apiClient.booking
                .changePickupPoint(result.id, result.pickup)
                .then((booking) => {
                    onSuccess(
                        t("bookings.actions.pickup_point_change.confirm", {
                            locator: booking.locator,
                        })
                    )

                    return { ...booking, id: entityId }
                })
                .catch((error) => {
                    onError(
                        t("bookings.actions.pickup_point_change.error", {
                            locator: result.locator,
                        })
                    )
                })
        },
        condition: (booking) => _has(booking.getActions(), "pickup_point_change")
    },
    {
        id: "assign_pax_manager_collaborator",
        title: t("bookings.actions.assign_pax_manager_collaborator.button"),
        withDialog: {
            title: t("bookings.actions.assign_pax_manager_collaborator.modal_title"),
            content: BookingAssignPaxManagerCollaborator
        },
        reloadOnSuccess: true,
        showLoading: true,
        onExecute: (result, progressHandler, onSuccess, onError) => {
            return apiClient.booking
                .assignPaxManagerCollaborator(entityId, {
                    manager_collaborator_id: result.manager_collaborator.id,
                    booking_rates: result.booking_rates
                })
                .then((booking) => {
                    onSuccess(
                        t("bookings.actions.assign_pax_manager_collaborator.confirm", {
                            locator: booking.locator,
                            name: result.manager_collaborator.name,
                        })
                    )

                    return { ...booking, id: entityId }
                })
                .catch((error) => {
                    onError(
                        t("bookings.actions.assign_pax_manager_collaborator.error", {
                            locator: result.locator,
                            name: result.manager_collaborator.name,
                        })
                    )

                    throw error
                })
        },
        condition: (booking) => user.isIntermediary() && _has(booking.getActions(), "assign_pax_manager_collaborator")
    },
    {
        id: "set_intermediary",
        title: t("bookings.actions.set_intermediary.button"),
        withDialog: {
            title: t("bookings.actions.set_intermediary.modal_title"),
            content: BookingSetIntermediary
        },
        reloadOnSuccess: true,
        showLoading: true,
        onExecute: (result, progressHandler, onSuccess, onError) => {
            return apiClient.order
                .setIntermediary(result.order_id, {
                    colaborator_id: result.collaborator_id,
                })
                .then((order) => {
                    onSuccess(
                        t("bookings.actions.set_intermediary.confirm", {
                            locator: result.locator,
                            name: order.collaborator.name,
                        })
                    )

                    return { ...order, id: entityId }
                })
                .catch((error) => {
                    onError(
                        t("bookings.actions.set_intermediary.error", {
                            locator: result.locator,
                        })
                    )

                    throw error
                })
        },
        condition: (booking) => _has(booking.getActions(), "collaborator_change")
    },
    {
        id: "create_invoice",
        title: t("bookings.actions.create_invoice.button"),
        onExecute: (booking, progressHandler, onSuccess, onError) => {
            navigate(availableRoutes.booking_create_client_invoice.path.replace(":entityId", entityId))
        },
        condition: () => user.hasPermission("create-invoice")
    },
    {
        id: "update_booking_confirmation",
        title: t("bookings.actions.booking_confirmation.button"),
        confirmDialog: {
            title: t("bookings.actions.booking_confirmation.modal_title"),
            content: (selection) =>
                t("bookings.actions.booking_confirmation.modal_content", {
                    locator: selection.locator,
                })
        },
        reloadOnSuccess: true,
        showLoading: true,
        onExecute: (booking, progressHandler, onSuccess, onError) => {
            return apiClient.booking
                .confirm(booking.id)
                .then((booking) => {
                    onSuccess(
                        t("bookings.actions.booking_confirmation.confirm", {
                            locator: booking.locator,
                            id: booking.id
                        })
                    )
                    return { ...booking, id: entityId }
                })
                .catch((error) => {
                    onError(
                        t("bookings.actions.booking_confirmation.error", {
                            locator: booking.locator
                        })
                    )

                    throw error
                })
        },
        condition: (booking) => booking.hasAction("confirm")
    },
    {
        id: "set_voucher",
        title: t("bookings.actions.set_voucher.button"),
        withDialog: {
            title: t("bookings.actions.set_voucher.modal_title"),
            content: BookingSetVoucher,
        },
        reloadOnSuccess: true,
        showLoading: true,
        onExecute: (booking, progressHandler, onSuccess, onError) => {
            return apiClient.booking.setVoucher(entityId, booking.voucher_id)
                .then((booking) => {
                    onSuccess(
                        t("bookings.actions.set_voucher.confirm")
                    )

                    return { ...booking, id: entityId }
                })
                .catch((error) => {
                    if (error.type === 'VOLCANO_SET_EXISTING_VOUCHER_ID') {
                        onError(
                            t("bookings.actions.set_voucher.error.type.VOLCANO_SET_EXISTING_VOUCHER_ID", { voucher_id: booking.voucher_id })
                        )
                    } else {
                        onError(
                            t("bookings.actions.set_voucher.error.type.default")
                        )
                    }
                })
        },
        condition: (booking) => booking.hasAction("set_voucher")
    },
    {
        id: "set_notes",
        title: t("bookings.actions.set_notes.button"),
        withDialog: {
            title: t("bookings.actions.set_notes.modal_title"),
            content: BookingSetNotes,
        },
        reloadOnSuccess: true,
        showLoading: true,
        onExecute: (booking, progressHandler, onSuccess, onError) => {
            return apiClient.booking.setNotes(entityId, booking.notes)
                .then((booking) => {
                    onSuccess(
                        t("bookings.actions.set_notes.confirm")
                    )

                    return { ...booking, id: entityId }
                })
                .catch((error) => {
                    onError(
                        t("bookings.actions.set_notes.error")
                    )

                    throw error
                })
        },
        condition: (booking) => booking.hasAction("set_notes")
    },
    {
        id: "create_gift",
        title: t("bookings.actions.create_gift.button"),
        withDialog: {
            title: t("bookings.actions.create_gift.modal_title"),
            content: (props) => BookingInfoGift({ ...props, action: 'create_gift'}),
        },
        reloadOnSuccess: true,
        showLoading: true,
        onExecute: (booking, progressHandler, onSuccess, onError) => {
            return apiClient.booking.setGift(entityId, booking.order.gift)
                .then((booking) => {
                    onSuccess(
                        t("bookings.actions.create_gift.confirm")
                    )

                    return { ...booking, id: entityId }
                })
                .catch((error) => {
                    onError(
                        t("bookings.actions.create_.error")
                    )

                    throw error
                })
        },
        condition: (booking) => booking.hasAction("set_gift") && !booking.order.gift
    },
    {
        id: "set_gift",
        title: t("bookings.actions.set_gift.button"),
        withDialog: {
            title: t("bookings.actions.set_gift.modal_title"),
            content: (props) => BookingInfoGift({...props, action: 'set_gift'}),
        },
        reloadOnSuccess: true,
        showLoading: true,
        onExecute: (booking, progressHandler, onSuccess, onError) => {
            return apiClient.booking.setGift(entityId, booking.order.gift)
                .then((booking) => {
                    onSuccess(
                        t("bookings.actions.set_gift.confirm")
                    )

                    return { ...booking, id: entityId }
                })
                .catch((error) => {
                    onError(
                        t("bookings.actions.set_.error")
                    )

                    throw error
                })
        },
        condition: (booking) => booking.hasAction("set_gift") && booking.order.gift
    },
    {
        id: "confirm_payment",
        title: t("bookings.actions.confirm_payment.button"),
        confirmDialog: {
            title: t("bookings.actions.confirm_payment.modal_title"),
            content: (selection) =>
                t("bookings.actions.confirm_payment.modal_content", {
                    locator: selection.locator,
                    id: selection.id
                })
        },
        reloadOnSuccess: true,
        showLoading: true,
        onExecute: (booking, progressHandler, onSuccess, onError) => {
            return apiClient.booking
                .confirmPayment(booking.id)
                .then((booking) => {
                    onSuccess(
                        t("bookings.actions.confirm_payment.confirm", {
                            locator: booking.locator
                        })
                    )

                    return { ...booking, id: entityId }
                })
                .catch((error) => {
                    onError(
                        t("bookings.actions.confirm_payment.error", {
                            locator: booking.locator
                        })
                    )

                    throw error
                })
        },
        condition: (booking) => booking.hasAction("confirm_payment")
    },
]
