import { createTabBarItem } from '../../../../components/helpers/fieldHelper';
import ZonePickupPoints from './components/tabs/ZonePickupPoints';


export const getTabNavigationItems = (user, apiClient, t) => [
    createTabBarItem(
        t("zones.view.related_collection_points"),
        ({ entity, data }) => (
            <ZonePickupPoints
                data={data.getItems()}
                entity={entity}
            />
        ),
        (zone) => {
            const params = {
                zone_id: zone.id,
                limit: 1000,
                offset: 0,
            }
            return apiClient.activityManager.pickupPoint.getPickupPoints(params)
        },
    ),
];