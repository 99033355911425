import React, { useRef, useState } from "react";
import { Container, Draggable } from "react-smooth-dnd";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import DeleteIcon from "@mui/icons-material/Delete";
import { Box, FormHelperText, IconButton, Dialog, DialogTitle, DialogContent } from '@mui/material';
import { Field } from 'formik';
import AddIcon from '@mui/icons-material/Add';
import _isArray from 'lodash/isArray';

const EditableListFormField = ({ field, value }) => {
    const configField = field
    const [items, setItems] = useState(field.options)
    const [openAddDialog, setOpenAddDialog] = useState(false)
    const dialogRef = useRef(null)

    // const [draggedItem, setDraggedItem] = useState(null);

    // const handleDragStart = ({ isSource, payload }) => {
    //     console.log(isSource, payload)

    //     if (isSource) {
    //         setDraggedItem(payload);
    //         console.log('Drag started:', payload);
    //     }
    // };

    const closeAddDialog = () => {
        if (dialogRef.current) {
            dialogRef.current.focus();
        }

        setOpenAddDialog(false)
    }

    const handleOnAdd = (newItems) => {
        const itemsCopy = [...items]
        let touched = false

        newItems = _isArray(newItems) ? newItems : [newItems]

        newItems.forEach((newItem) => {
            if (!items.some((item) => String(item.value) === String(newItem.value))) {
                itemsCopy.push(newItem)
                touched = true
            }
        })

        if (touched) {
            setItems(itemsCopy)
        }

        return itemsCopy
    }

    const handleOnDrop = (removedIndex, addedIndex) => {
        const itemsCopy = [...items]
        const [movedElement] = itemsCopy.splice(removedIndex, 1)

        itemsCopy.splice(addedIndex, 0, movedElement)

        setItems(itemsCopy)
        // setDraggedItem(null)

        return itemsCopy
    }

    const handleOnDelete = (index) => {
        const itemsCopy = [...items]

        itemsCopy.splice(index, 1);

        setItems(itemsCopy)

        return itemsCopy
    }

    const Content = configField.addContent || null

    return (

        <Field
            name={configField.id}
            label={configField.label}
        >
            {({
                field,
                form: { touched, errors, setFieldValue, setFieldTouched },
                meta,
            }) => (
                <>
                    {Content && <Dialog
                        open={openAddDialog}
                        fullWidth={true}
                        maxWidth="lg"
                        onClose={closeAddDialog}
                        ref={dialogRef}
                    >
                        <DialogTitle>{configField.addContentTitle}</DialogTitle>
                        <DialogContent>
                            <Content
                                {...configField.addContent.props}
                                onConfirm={(data, progressHandler, onSuccessHandler, onErrorHandler) => {
                                    closeAddDialog()
                                    const val = handleOnAdd(data.map((product) => ({ value: product.id, label: product.label })))

                                    setFieldValue(field.name, val, true)
                                    setFieldTouched(field.name, true, false)
                                }}
                                onCancel={closeAddDialog}
                            />
                        </DialogContent>
                    </Dialog>}
                    <Box>
                        {Content && <Box display="flex" justifyContent="flex-end" mr={2}>
                            <IconButton
                                aria-label="add"
                                color="primary"
                                sx={{ padding: 0, align: "right" }}
                                onClick={() => setOpenAddDialog(true)}
                            >
                                <AddIcon />
                            </IconButton>
                        </Box>}
                        <List>
                            <Container
                                dragHandleSelector=".drag-handle"
                                lockAxis="y"
                                // onDragStart={handleDragStart}
                                onDrop={({ removedIndex, addedIndex }) => {
                                    const val = handleOnDrop(removedIndex, addedIndex)

                                    setFieldValue(field.name, val, true)
                                    setFieldTouched(field.name, true, false)
                                }}
                            >
                                {items.map((option, index) => (
                                    <Draggable key={index}>
                                        <ListItem
                                            className={configField.allowSort ? 'drag-handle' : ''}
                                            sx={configField.allowSort && {
                                                cursor: 'pointer',
                                                //backgroundColor: draggedItem === index ? 'background.info.main' : '',
                                            }}
                                        >
                                            {configField.allowSort && <ListItemAvatar>
                                                <DragHandleIcon />
                                            </ListItemAvatar>}
                                            <ListItemText primary={option.label} />
                                            {configField.allowDelete && <ListItemSecondaryAction>
                                                <IconButton
                                                    aria-label="delete"
                                                    color="primary"
                                                    sx={{ padding: 0, align: "right" }}
                                                    onClick={() => {
                                                        const val = handleOnDelete(index)

                                                        setFieldValue(field.name, val, true)
                                                        setFieldTouched(field.name, true, false)
                                                    }}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            </ListItemSecondaryAction>}
                                        </ListItem>
                                    </Draggable>
                                ))}
                            </Container>
                        </List>
                        {meta.error && meta.touched && <FormHelperText error={true}>{meta.error}</FormHelperText>}
                    </Box>
                </>
            )}
        </Field>

    );
};

export default EditableListFormField