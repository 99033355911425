import SummaryTable from '../../../components/Volcano/Notifications/Summary';
import constants from '../../../components/entity/constants';
import { createArrayField, createCallbackField, createDataSetFacet, createFacetField, createFacetsContainer, createField, createLanguagesMapping, languagesCode2, notificationStatesMapping, notificationTemplateTypesMapping } from '../../../components/helpers/fieldHelper';
import _join from "lodash/join"
import _isEmpty from "lodash/isEmpty"

export const getHeaderContent = (t, user) => [
    createDataSetFacet(t("notifications.view.facet_information"), [
        createField("id", "Id", constants.STRING_TYPE),
        createField(
            "name",
            t("notifications.fields.name"),
            constants.STRING_TYPE
        ),
        createField(
            "created",
            t("notification_templates.fields.created"),
            constants.DATETIME_TYPE
        ),
        createField(
            "enterprise_name",
            t("common.enterprise"),
            constants.STRING_TYPE
        ),
        createField(
            "type",
            t("notification_templates.fields.type"),
            constants.STRING_TYPE,
            notificationTemplateTypesMapping(t)
        ),
        createField(
            "date",
            t("notifications.fields.date"),
            constants.DATE_TYPE,
            null,
            (entity) => entity.date
        ),
    ]),
    createDataSetFacet(
        t("notification_templates.view.facet_recipients"),
        [
            createCallbackField(
                "recipient_types",
                t("notifications.fields.recipients.type"),
                constants.STRING_TYPE,
                (value) => _join(value.map((element) => t("notification_templates.constants." + element)),", "),
                (entity) => !_isEmpty(entity.recipient_types)
            ),        
/*            createArrayField(
                createField(
                    "send_types",
                    t("notification_templates.fields.recipients.send"),
                    constants.ARRAY_TYPE,
                    null,
                    (entity) => !_isEmpty(entity.send_types)
                ),
                ""
            ),*/
            createCallbackField(
                "send_types",
                t("notifications.fields.recipients.send"),
                constants.STRING_TYPE,
                (value) => _join(value.map((element) => t("notification_templates.constants." + element)),", "),
                (entity) => !_isEmpty(entity.send_types)
            ),        
            createArrayField(
                createField(
                    "languages",
                    t("notification_templates.fields.recipients.language"),
                    constants.ARRAY_TYPE,
                    createLanguagesMapping(t, languagesCode2(), true),
                    (entity) => !_isEmpty(entity.languages)
                ),
                ""
            ),
        ],
        (entity) => (entity.recipients)
    ),
    createFacetsContainer([
        createFacetField("object_status", {
            size: "small",
            field: 
                createField(
                    "state",
                    t("notifications.fields.state"),
                    constants.STRING_TYPE,
                    notificationStatesMapping(t)
                ),
        }),
    ]),
    createFacetsContainer([
        createFacetField("object_status", {
            size: "small",
            field: createCallbackField(
                "summary",
                t("notifications.fields.summary"),
                constants.STRING_TYPE,
                (value) => 
                    <SummaryTable value={value} />
            ),
            condition: (entity) => (entity.state === 'finished' || entity.state === 'cancelled')
        }),
    ]),
];
