import { useTranslation } from "react-i18next";
import { getRecipientsFinishedActions, getResult } from "./NotificationRecipients.functions";
import { createCallbackField, createField, createLanguagesMapping } from "../../../../../../components/helpers/fieldHelper";
import constants from "../../../../../../components/entity/constants";
import { Box } from "@mui/material";
import { useVolcanoApiClient } from "../../../../../../context/VolcanoApiClientProvider";
import { useState } from "react";
import CollectionDataTable from "../../../../../../components/Datatable/CollectionDataTable";
import { MailOutlineOutlined, SmsOutlined } from "@mui/icons-material";

const getTableFilter = (t) => ([
    createField(
        "name",
        t("notifications.filter.name"),
        constants.TEXT_TYPE
    ),
    createField(
        "email",
        t("notifications.fields.email"),
        constants.TEXT_TYPE
    ),
    createField(
        "phone",
        t("notifications.fields.phone"),
        constants.TEXT_TYPE
    ),
])

const NotificationRecipientsGuides = ({ defaultParams }) => {
    const { t } = useTranslation("vbms");
    const { apiClient } = useVolcanoApiClient()

    const entityId = useState(defaultParams.notification_id)
 
    const actions = getRecipientsFinishedActions(entityId, 'guide', t)

    const columns = [
        createField(
            "name",
            t("notifications.filter.name"),
            constants.TEXT_TYPE
        ),
        createField(
            "email",
            t("notifications.fields.email"),
            constants.STRING_TYPE
        ),
        createField(
            "phone",
            t("notifications.fields.phone"),
            constants.STRING_TYPE
        ),
        createField(
            "language",
            t("notifications.fields.language"),
            constants.STRING_TYPE,
            createLanguagesMapping(t)
        ),
        createCallbackField(
            "logs",
            <MailOutlineOutlined/>,
            constants.STRING_TYPE,
            (value) => {
                if (value && value["email"]) {
                    return (
                        <Box>{getResult(value["email"], t)}</Box>
                    )
                } else {
                    return
                }
            },
        ),
        createCallbackField(
            "logs",
            <SmsOutlined/>,
            constants.STRING_TYPE,
            (value) => {
                if (value && value["sms"]) {
                    return (
                        <Box>{getResult(value["sms"], t)}</Box>
                    )
                } else {
                    return
                }
            },
        ),

    ]

    const filterConfig = getTableFilter(t)

    return (
        <CollectionDataTable
            fetcher={(query) => {
                const params = {
                    ...defaultParams
                    , ...query
                }
                return apiClient.notifications.getRecipients(defaultParams.notification_id, params)
            }}
            columns={columns}
            actions={actions}
            filterConfig={filterConfig}
        />
    )

}

export default NotificationRecipientsGuides