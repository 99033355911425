import constants from '../../../components/entity/constants';
import { createArrayField, createCallbackField, createDataSetFacet, createField, createLanguagesMapping, languagesCode2, notificationTemplateTypesMapping } from '../../../components/helpers/fieldHelper';
import _join from "lodash/join"
import _isEmpty from "lodash/isEmpty"

export const getHeaderContent = (t, user) => [
    createDataSetFacet(t("notification_templates.view.facet_information"), [
        createField("id", "Id", constants.STRING_TYPE),
        createField(
            "name",
            t("notification_templates.fields.name"),
            constants.STRING_TYPE
        ),
        createField(
            "created",
            t("notification_templates.fields.created"),
            constants.DATETIME_TYPE
        ),
        createField(
            "enterprise_name",
            t("common.enterprise"),
            constants.STRING_TYPE
        ),
        createField(
            "notification_template_type",
            t("notification_templates.fields.type"),
            constants.STRING_TYPE,
            notificationTemplateTypesMapping(t)
        ),
        createField(
            "system_template",
            t("notification_templates.fields.system_template"),
            constants.BOOLEAN_TYPE,
        ),
    ]),
    createDataSetFacet(
        t("notification_templates.view.facet_recipients"),
        [
            createCallbackField(
                "recipient_types",
                t("notification_templates.fields.recipients.type"),
                constants.STRING_TYPE,
                (value) => _join(value.map((element) => t("notification_templates.constants." + element)),", "),
                (entity) => !_isEmpty(entity.recipient_types)
            ),        
            createCallbackField(
                "send_types",
                t("notifications.fields.recipients.send"),
                constants.STRING_TYPE,
                (value) => _join(value.map((element) => t("notification_templates.constants." + element)),", "),
                (entity) => !_isEmpty(entity.send_types)
            ),        
            createArrayField(
                createField(
                    "languages",
                    t("notification_templates.fields.recipients.language"),
                    constants.ARRAY_TYPE,
                    createLanguagesMapping(t, languagesCode2(), true),
                    (entity) => !_isEmpty(entity.languages)
                ),
                ""
            ),
        ],
        (entity) => (entity.recipients)
    )
];